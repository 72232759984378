html {
  font-size: 16px; /* Sets the base font size */
}

body {
  font-family: "Outfit";
}

/* Add this to your CSS file */
.scrollable-container {
  max-height: 400px; /* Adjust this height as needed */
  min-height: 300px; /* Adjust this height as needed */
  overflow-y: auto;
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: #4f4c50 #1d1b1d; /* For Firefox */
}

.scrollable-container-settings {
  overflow-y: auto;
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: #4f4c50 #1d1b1d; /* For Firefox */
}

input:focus {
  outline: none;
  box-shadow: none;
}

input:focus::-webkit-focus-ring-color {
  outline: none;
}

input:focus {
  -webkit-tap-highlight-color: transparent;
}

.scrollable-content {
  /* Scrollbar styling */
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: #333 #100f10; /* For Firefox (thumb and track) */
  overflow-y: auto;
}

/* General scrollbar styling for WebKit-based browsers (Chrome, Edge, Safari) */
.scrollable-content-notifications {
  scrollbar-width: thin; /* For Firefox: thin scrollbar */
  scrollbar-color: #212121 #31303080; /* For Firefox: thumb and track color */
  overflow-y: auto;

  /* WebKit-based browsers: Chrome, Edge, Safari */
  &::-webkit-scrollbar {
    width: 8px; /* Width of the scrollbar */
    height: 8px; /* Height of the scrollbar (for horizontal scrolling) */
  }

  &::-webkit-scrollbar-thumb {
    background-color: #333; /* Scrollbar thumb (slider) color */
    border-radius: 4px; /* Rounded corners for the thumb */
  }

  &::-webkit-scrollbar-track {
    background-color: #100f10; /* Scrollbar track color */
    border-radius: 4px; /* Rounded corners for the track */
  }
}

.scrollable-chat {
  max-height: 65vh; /* Adjust this height as needed */
  min-height: 60vh; /* Adjust this height as needed */
  overflow-y: auto;
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: #4f4c50 #1d1b1d; /* For Firefox */
}

.scrollable-version {
  /* Scrollbar styling */
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: #333 #100f10; /* For Firefox (thumb and track) */
  overflow-y: auto;
}

@media (max-width: 768px) {
  .scrollable-content.small-screen {
    max-height: calc(100vh - 14.5rem);
  }
  .scrollable-content.small-screen.is-audio-playing {
    max-height: calc(100vh - 18.5rem);
  }
}

.scrollable-content-team-settings {
  scrollbar-width: thin;
  scrollbar-color: #333 #100f10;
  overflow-y: auto;
  max-height: calc(100vh - 220px); /* For small screens */
}

@media (min-width: 768px) {
  .scrollable-content-team-settings {
    max-height: calc(100vh - 90px); /* For medium screens and up */
  }
}

@keyframes move-to-fro {
  0% {
    left: 0;
    transform: translateX(0); /* Ball starts at the left */
  }
  16.67% {
    left: 0;
    transform: translateX(0); /* Pause at the left */
  }
  50% {
    left: 100%;
    transform: translateX(-100%); /* Ball reaches the right */
  }
  66.67% {
    left: 100%;
    transform: translateX(-100%); /* Pause at the right */
  }
  100% {
    left: 0;
    transform: translateX(0); /* Ball returns to the left */
  }
}

.animate-move-to-fro {
  animation: move-to-fro 6s ease-in-out infinite;
}

/* Mask reveal for the waveform with 1-second pause and adjusted for ball center */
@keyframes reveal-mask {
  0% {
    mask-size: 0% 100%; /* Initially hidden */
    mask-position: 0 0; /* Start revealing from the ball center */
  }
  16.67% {
    mask-size: 0% 100%; /* Pause at the left */
    mask-position: 0 0;
  }
  50% {
    mask-size: calc(100% - 20px) 100%; /* Fully revealed, adjusted for ball center */
    mask-position: 0 0;
  }
  66.67% {
    mask-size: calc(100% - 20px) 100%; /* Pause at the right */
    mask-position: 0 0;
  }
  100% {
    mask-size: 0% 100%; /* Hidden again when the ball returns */
    mask-position: 0 0;
  }
}

.mask-waveform {
  mask-image: linear-gradient(to right, black 100%, transparent 0%);
  mask-size: 0% 100%; /* Start hidden */
  mask-repeat: no-repeat;
  animation: reveal-mask 6s ease-in-out infinite; /* Sync mask animation with ball movement */
}

@keyframes scroll-left {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

.animate-scroll-left {
  animation: scroll-left 12s linear infinite;
  width: 200%; /* Make sure the inner div is twice the width of the visible area */
}
/* Container with darker edges fading to lighter using #0f0e0f */
.fade-container::before,
.fade-container::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  width: 150px; /* Wider fade area */
  pointer-events: none;
  z-index: 1;
}

.fade-container::before {
  left: 0;
  background: linear-gradient(
    to right,
    rgba(15, 14, 15, 0.8),
    rgba(15, 14, 15, 0)
  ); /* Dark to light fade on the left */
}

.fade-container::after {
  right: 0;
  background: linear-gradient(
    to left,
    rgba(15, 14, 15, 0.8),
    rgba(15, 14, 15, 0)
  ); /* Dark to light fade on the right */
}

/* Bottom fade effect that covers 1/8th of the height */
.bottom-fade::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 39.5%; /* 1/8th of the container's height */
  pointer-events: none;
  background: linear-gradient(
    to top,
    rgba(15, 14, 15, 0.8),
    transparent
  ); /* Fade from bottom to top */
  z-index: 1;
}

.audio-bars {
  position: absolute;
  right: 16px;
  width: 40px; /* Adjusted width to fit the closer bars */
  height: 20px; /* Adjusted height as needed */
  margin: 0 auto; /* Center the container horizontally */
}

.bar {
  position: absolute;
  border-radius: 40px;
  width: 2px; /* Width of each bar (already set inline) */
  background-color: white; /* Changed bar color to white */
  transition: height 0.2s ease, top 0.2s ease; /* Smooth transition */
}

/* Position each bar closer together */
.bar1 {
  right: 0px;
}

.bar2 {
  right: 6px;
}

.bar3 {
  right: 12px;
}

.bar4 {
  right: 18px;
}

@keyframes slowMove {
  0%,
  100% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(30px, -30px);
  }
}

@keyframes slowMoveReverse {
  0%,
  100% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(-30px, 30px);
  }
}

.animate-slow-move {
  animation: slowMove 14s ease-in-out infinite;
}

.animate-slow-move-reverse {
  animation: slowMoveReverse 14s ease-in-out infinite;
}

@keyframes slowRotateLeft {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg); /* Rotates counterclockwise */
  }
}

@keyframes slowRotateRight {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg); /* Rotates counterclockwise */
  }
}

.animate-slow-rotate-left {
  animation: slowRotateLeft 16s linear infinite; /* Adjust timing as needed */
}

.animate-slow-rotate-right {
  animation: slowRotateRight 5s linear infinite; /* Adjust timing as needed */
}

.darker-filter {
  filter: brightness(0.95); /* Lower value makes it darker */
}

@media (min-width: 768px) {
  .checkbox-container {
    opacity: 0;
    transition: opacity 0.6s ease-in-out;
  }

  .project-item:hover .checkbox-container {
    opacity: 1;
  }
}
/* SmartAssistModal.css (optional) */

.sa-modal-overlay {
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sa-modal-container {
  background: #fff;
  width: 90%;
  max-width: 900px;
  border-radius: 8px;
  position: relative;
  padding: 16px;
  overflow: hidden;
}

.sa-modal-close {
  position: absolute;
  top: 8px;
  right: 8px;
  background: transparent;
  border: none;
  font-size: 18px;
  cursor: pointer;
}

.sa-modal-title {
  text-align: center;
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.sa-modal-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  gap: 12px;
  margin-bottom: 1rem;
}

/* Card styles */
.sa-card {
  position: relative;
  border: 2px solid transparent;
  border-radius: 6px;
  overflow: hidden;
  cursor: pointer;
}

.sa-card-selected {
  border-color: #3078e4;
}

.sa-card-image-wrapper {
  width: 100%;
  padding-top: 100%; /* 1:1 aspect ratio */
  position: relative;
}

.sa-card-image {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.sa-card-overlay {
  position: absolute;
  inset: 0;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 8px;
}

.sa-card-title {
  color: white;
  font-size: 0.9rem;
  font-weight: bold;
}

.sa-card-checkbox {
  transform: scale(1.2);
  margin-right: 8px;
}

.sa-card-play-btn {
  background: #fff;
  color: #000;
  border: none;
  border-radius: 4px;
  padding: 4px 6px;
  cursor: pointer;
  font-size: 0.8rem;
}

.sa-modal-footer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.sa-modal-btn,
.sa-modal-btn-outline {
  border: none;
  cursor: pointer;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border-radius: 4px;
}

.sa-modal-btn {
  background: linear-gradient(to right, #fc10f2, #3078e4);
  color: white;
}

.sa-modal-btn:disabled {
  background: #ccc;
  cursor: not-allowed;
}

.sa-modal-footer-2 {
  text-align: center;
  margin-bottom: 1rem;
}

.sa-modal-btn-outline {
  background: transparent;
  border: 2px solid #3078e4;
  color: #3078e4;
}

.sa-step-dots {
  display: flex;
  justify-content: center;
  gap: 6px;
  margin-bottom: 1rem;
}

.sa-step-dot {
  width: 8px;
  height: 8px;
  background: #ccc;
  border-radius: 50%;
}

.sa-step-dot-active {
  background: #3078e4;
}

.spotlight-text {
  position: relative;
  overflow: hidden;
  background: linear-gradient(
    90deg,
    #444444,
    #444444 45%,
    #ffffff 48%,
    #444444 52%,
    #444444
  );
  background-size: 400% 100%; /* Wider movement */
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: spotlight 27s linear infinite;
}

/* Spotlight Animation */
@keyframes spotlight {
  0% {
    background-position: 400% 0;
  }
  100% {
    background-position: -400% 0;
  }
}
